<template>
  <div class="opportunities d-flex flex-row align-center justify-between">
    <div class="left">
      <h5 class="category">{{ category }}</h5>
      <h3 class="title">{{ title }}</h3>
      <slot />
      <Button :width="250" :backGround="'#000'" :color="'#fff'">{{
        btnSlogan
      }}</Button>
    </div>
    <div class="right">
      <img :src="require(`@/assets/images/Others/${img}`)" alt="order" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "Opportunities",
  props: {
    category: {
      type: String,
      required: false,
      default: "Delivery",
    },
    title: {
      type: String,
      required: false,
      default: "Multiple order easy as 1-2-3",
    },
    btnSlogan: {
      type: String,
      required: false,
      default: "About delivery",
    },
    img: {
      type: String,
      required: false,
      default: "order.png",
    },
  },
  components: {
    Button,
  },
};
</script>



