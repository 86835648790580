<template>
  <div class="loyalty d-flex flex-row align-center justify-between">
    <div class="left">
      <img
        src="@/assets/images/Others/loyalty-programm.png"
        alt="loyalty-programm.png"
        class="phone"
      />
      <img
        src="@/assets/images/Others/loyalty-programm1.png"
        alt="loyalty-programm.png"
        class="pop-up1"
      />
      <img
        src="@/assets/images/Others/loyalty-programm2.png"
        alt="loyalty-programm.png"
        class="pop-up2"
      />
    </div>
    <div class="right">
      <h5>{{ category }}</h5>
      <h3>{{ title }}</h3>
      <p>
        {{ paragraph }}
      </p>
      <div class="btn-container d-flex flex-row align-baseline">
        <img src="@/assets/icons/Others/google-play.png" alt="mobile-app" class="pointer"/>
        <img src="@/assets/icons/Others/apple-store.png" alt="mobile-app" class="pointer"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loyalty",
  props: {
    category: {
      type: String,
      required: false,
      default: "Loyalty Programm",
    },
    title: {
      type: String,
      required: false,
      default: "We appreciate your every order",
    },
    paragraph: {
      type: String,
      required: false,
      default:
        "Recieve one stamp for each €10 you spend. Once you collected 10 stamps, you will recieve €5 vaucher. Collect them or share with your family and friends!",
    },
    btns: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

