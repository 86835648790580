<template>
  <div class="business">
    <div class="header d-flex flex-row">
      <div class="left d-flex flex-column justify-center">
        <h3>Get a business account with us!</h3>
        <p>Get a business account and win twice!</p>

        <div class="scroll">
          <img
            src="@/assets/images/Delivery/scroll.png"
            alt="scroll"
            class="scroll-arrow"
          />
        </div>
      </div>
      <div class="right d-flex flex-row align-center">
        <img src="@/assets/images/Business/girl.png" alt="laptop-flower" />
      </div>
    </div>

    <div class="why-to-open d-flex flex-row align-center space-around">
      <div class="left">
        <h5>Business</h5>

        <h3>Why to open a business account?</h3>
        <p>
          Choosing us, you can benefit twice! Get your discount as a company and
          get personal discount to all your employees!
        </p>
      </div>
      <div class="right">
        <img
          src="@/assets/images/Business/business-account.png"
          alt="business-account.png"
          class="flower"
        />
        <img
          src="@/assets/images/Business/business-account1.png"
          alt="business-account.png1"
          class="pop-up"
        />
        <img
          src="@/assets/images/Business/business-account2.png"
          alt="business-account.png2"
          class="pop-up2"
        />
      </div>
    </div>

    <div class="opportunities-wrapper">
      <Opportunities
        :img="'order.png'"
        :category="'Orders'"
        :btnSlogan="'create account'"
      >
        <div class="order-list">
          <ol>
            <li>
              <p>Add new delivery addresses or select from your address book</p>
            </li>
            <li><p>Select flowers for each delivery destination</p></li>
            <li>
              <p>
                Take a look at your order summary, add personalized greetings if
                needed and send!
              </p>
            </li>
            <li>
              <p>Delivery throughout the Netherlands, 7 days per week.</p>
            </li>
          </ol>
        </div>
      </Opportunities>
    </div>

    <Loyalty
      :title="'Collect stamps and get extra discounts'"
      :paragraph="'Use our app to recieve one stamp for each €10 you spend. Once you collected 10 stamps, you will recieve €5 vaucher. Collect them or share with your family, friends or colleagues!'"
      :btns="true"
    />

    <div class="delivery-benefit d-flex flex-row align-center justify-between">
      <div class="left">
        <h5>Delivery</h5>
        <h3>Benefit on delivery cost!</h3>
        <p>
          Benefit on delivery cost! We have special delivery prices for a
          business accounts! Save up to €5 on each delivery!
        </p>
      </div>
      <div class="right">
        <img
          src="@/assets/images/Delivery/laptop-flowers.png"
          alt="laptop-flowers.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Opportunities from "@/components/Opportunities";
import Loyalty from "@/components/Loyalty";

export default {
  name: "Business",
  components: {
    Button,
    Opportunities,
    Loyalty,
  },
};
</script>

